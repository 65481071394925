import Vue from "vue";

var API_URL = 'https://staging.cheapcomforts.com/api/'

var API_URL_CIT = 'https://staging.cheapcomforts.com/api/citizen/'
import axios from 'axios'
import router from "./router";
export var Appurl = 'https://staging.cheapcomforts.com/';

window.axios = axios




window.axios.interceptors.request.use(request => {
    var citizenVal = false;
    if (request.url.indexOf("project") > -1) {
        citizenVal = true
    }
    if (citizenVal === false && request.url !== API_URL + 'register/spotter' && request.url !== API_URL + 'login/spotter') {
        const token = localStorage.getItem('isLoggedin');
        if (token) {
            request.headers.common['Authorization'] = `Bearer ${token}`
            request.headers.common['Content-Type'] = 'application/json'
        }
    }
    return request
}, function (error) {
    if (error.response.status === 401) {
        // if you ever get an unauthorized, logout the user
        localStorage.removeItem('userPermissions');
        localStorage.removeItem('isLoggedin');
        localStorage.removeItem('email');
        localStorage.removeItem('password');
        router.push("/");
        // you can also redirect to /login if needed !
    }
})

const API = {
    resetPassword(data, cb, errorCB) {
        axios
            .post(API_URL + 'callResetPassword', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                console.log(err)
            })
    }, login(data, cb, errorCB) {

        axios
            .post(API_URL + 'login', data)
            .then(resp => {
                if (resp.status === 201) {
                    cb(resp.data);
                } else {
                    cb(resp.data);
                }
            })
            .catch(err => {
                errorCB(err.response.data);
            });

    },

    submitContactForm(data, cb, errorCB) {
        axios
            .post(API_URL + 'send-contact-form', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response.data)
            })
    },
    checkEmail(data, cb, errorCB) {
        axios
            .post(API_URL + 'sendResetPasswordEmail', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response.data)
            })
    },
    registerUser(data, cb, errorCB) {
        axios
            .post(API_URL + 'register_property_owner', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response.data)
            })
    },
    savePayment(data, cb, errorCB) {
        axios
            .post(API_URL + 'add_payment', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response.data)
            })
    },
    saveRating(data, cb, errorCB) {
        axios
            .post(API_URL + 'add_rating', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response.data)
            })
    },
    addProperty(data, cb, errorCB) {
        axios
            .post(API_URL + 'add_property', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response.data)
            })
    },
    updateProperty(data, cb, errorCB) {
        axios
            .put(API_URL + 'update_property/' + data.id, data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response.data)
            })
    },
    deleteProperty(id, cb, errorCB) {
        axios
            .delete(API_URL + "delete_property/" + id)
            .then((resp) => {
                cb(resp);
            })
            .catch((err) => {
                errorCB(err);
            });
    },
    getCities(country, cb, errorCB) {
        axios
            .post(API_URL + "get_cities", {
                country: country,
            })
            .then((resp) => {
                cb(resp);
            })
            .catch((err) => {
                errorCB(err);
            });
    },
    Sendmail(id, cb, errorCB) {
        axios
            .post(API_URL + "update_account", {
                userid: id,
            })
            .then((resp) => {
                cb(resp);
            })
            .catch((err) => {
                errorCB(err);
            });
    },

    getProperty(id, cb, errorCB) {
        axios
            .get(API_URL + "get_Property/" + id)
            .then((resp) => {
                cb(resp);
            })
            .catch((err) => {
                errorCB(err);
            });
    },
    getallProperty(cb, errorCB) {
        axios
            .get(API_URL + "get_all_Property/")
            .then((resp) => {
                cb(resp);
            })
            .catch((err) => {
                errorCB(err);
            });
    },
    getalllanguages(country, cb, errorCB) {
        axios
            .post(API_URL + "getalllanguages", {
                country: country,
            })
            .then((resp) => {
                cb(resp);
            })
            .catch((err) => {
                errorCB(err);
            });
    },
    getFilterPrices(currency, cb, errorCB) {
        axios.post(API_URL + "filter_prices", {
            currency: currency
        })
            .then((resp) => {
                cb(resp);
            })
            .catch((err) => {
                errorCB(err);
            });
    },
    getMaxPrice(currency, cb, errorCB) {
        axios.post(API_URL + "maxPrice", {
            currency: currency
        })
            .then((resp) => {
                cb(resp);
            })
            .catch((err) => {
                errorCB(err);
            });
    },
    getallcurriencies(cb, errorCB) {
        axios
            .get(API_URL + "getallcurriencies")
            .then((resp) => {
                cb(resp);
            })
            .catch((err) => {
                errorCB(err);
            });
    },
    getCurrency(code, cb, errorCB) {
        axios
            .get(API_URL + "getcurrency/" + code)
            .then((resp) => {
                cb(resp);
            })
            .catch((err) => {
                errorCB(err);
            });
    },
    getFavProperties(cb, errorCB) {
        axios
            .get(API_URL + "get_fav_Properties")
            .then((resp) => {
                cb(resp);
            })
            .catch((err) => {
                errorCB(err);
            });
    },
    getusersbooking(cb, errorCB) {
        axios
            .get(API_URL + "get_users_bookings")
            .then((resp) => {
                cb(resp);
            })
            .catch((err) => {
                errorCB(err);
            });
    },
    getIcomeStatistics(cb, errorCB) {
        axios
            .get(API_URL + "icome_statistics")
            .then((resp) => {
                cb(resp);
            })
            .catch((err) => {
                errorCB(err);
            });
    },
    getdashboardstats(id, cb, errorCB) {
        axios
            .post(API_URL + "get_dashboard_stats", {
                userid: id,
            })
            .then((resp) => {
                cb(resp);
            })
            .catch((err) => {
                errorCB(err);
            });
    },
    getBookingsByPropertyID(propertyId, cb, errorCB) {
        axios
            .get(API_URL + "get_booking_propertyId/" + propertyId)
            .then((resp) => {
                cb(resp);
            })
            .catch((err) => {
                errorCB(err);
            });
    },
    addFavourite(propertyId, cb, errorCB) {
        axios
            .get(API_URL + 'add_favourite/' + propertyId)
            .then(resp => {
                cb(resp)
            })
            .catch(err => {
                errorCB(err.response.data)
            })
    },
    cancelbooking(params, cb, errorCB) {
        axios
            .post(API_URL + 'cancel_booking', params)
            .then(resp => {

                if (resp.status == 200) {
                    cb(resp.data)
                }
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    getProperties(params, cb, errorCB) {
        axios
            .post(API_URL + 'get_properties', params)
            .then(resp => {

                if (resp.status == 200) {
                    cb(resp.data)
                }
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    exportcalander(propertyId, cb, errorCB) {
        axios
            .post(API_URL + "exportcalander", {
                propertyId: propertyId,
            })
            .then((resp) => {
                cb(resp);
            })
            .catch((err) => {
                errorCB(err);
            });
    },
    geticaldata(params, cb, errorCB) {
        const queryString = new URLSearchParams(params).toString();
        const url = `${API_URL}geticaldata?${queryString}`;
        axios
            .get(url)
            .then(resp => {
                if (resp.status === 200) {
                    cb(resp.data);
                }
            })
            .catch(err => {
                errorCB(err.response);
            });
    },
    saveicalfile(params, cb, errorCB) {
        axios
            .post(API_URL + 'saveicalfile', params)
            .then(resp => {

                if (resp.status == 200) {
                    cb(resp.data)
                }
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    bookingdetails(params, cb, errorCB) {
        const queryString = new URLSearchParams(params).toString();
        const url = `${API_URL}bookingdetails?${queryString}`;
        axios
            .get(url)
            .then(resp => {
                if (resp.status === 200) {
                    cb(resp.data);
                }
            })
            .catch(err => {
                errorCB(err.response);
            });
    },
    getAllCountries(cb, errorCB) {
        axios
            .get(API_URL + 'countries')
            .then(resp => {

                if (resp.status == 200) {
                    cb(resp.data)
                }
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    getPropertyOwnerBookings(params, cb, errorCB) {
        axios
            .post(API_URL + 'get_bookings', params)
            .then(resp => {

                if (resp.status == 200) {
                    cb(resp.data)
                }
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    getAllBookings(params, cb, errorCB) {
        axios
            .post(API_URL + 'get_all_bookings', params)
            .then(resp => {

                if (resp.status == 200) {
                    cb(resp.data)
                }
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    getAllusers(params, cb, errorCB) {
        axios
            .post(API_URL + 'get_users', params)
            .then(resp => {

                if (resp.status == 200) {
                    cb(resp.data)
                }
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    getRatingAndReviews(id, cb, errorCB) {
        axios
            .get(API_URL + 'get_rating_reviews/' + id)
            .then(resp => {

                if (resp.status == 200) {
                    cb(resp.data)
                }
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    approveProperty(id, cb, errorCB) {
        axios
            .post(API_URL + "approve_property/" + id)
            .then((resp) => {
                cb(resp);
            })
            .catch((err) => {
                errorCB(err);
            });
    },
    getAllProperties(params, cb, errorCB) {
        axios
            .post(API_URL + 'get_all_properties', params)
            .then(resp => {

                if (resp.status == 200) {
                    cb(resp.data)
                }
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    getPropertyByIds(params, cb, errorCB) {
        axios
            .post(API_URL + 'get_by_ids', params)
            .then(resp => {

                if (resp.status == 200) {
                    cb(resp.data)
                }
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    deleteclient(data, cb, errorCB) {
        axios
            .post(API_URL + 'deleteclient', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response.data)
            })
    },
    deleteUser(data, cb, errorCB) {
        axios
            .post(API_URL + 'deleteUser', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response.data)
            })
    },
    deleteQuestion(data, cb, errorCB) {
        axios
            .delete(API_URL + 'questions/delete/' + data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response.data)
            })
    },
    deleteCategory(data, cb, errorCB) {
        axios
            .delete(API_URL + 'categories/delete/' + data, '')
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response.data)
            })
    },
    deleteSpotter(data, cb, errorCB) {
        axios
            .delete(API_URL + 'spotter/delete/' + data, '')
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response.data)
            })
    },
    deleteClientSpot(data, cb, errorCB) {
        axios
            .delete(API_URL + 'spot/delete/' + data, '')
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response.data)
            })
    },
    deleteLocation(data, cb, errorCB) {
        axios
            .delete(API_URL + 'locations/delete/' + data, '')
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response.data)
            })
    },
    deleteSpot(data, cb, errorCB) {
        axios
            .delete(API_URL + 'spotTypes/delete/' + data, '')
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response.data)
            })
    },
    getroles(params, cb, errorCB) {
        axios
            .post(API_URL + 'roles', params)
            .then(resp => {

                if (resp.status == 200) {
                    cb(resp.data)
                }
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    getUserRoles(cb, errorCB) {
        axios
            .get(API_URL + 'users/getRoles')
            .then(resp => {
                if (resp.status == 200) {
                    cb(resp.data)
                }
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    getCategories(cb, errorCB) {
        axios
            .get(API_URL + 'getCategories')
            .then(resp => {
                if (resp.status == 200) {
                    cb(resp.data)
                }
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    getAllUser(param, cb, errorCB) {
        axios
            .post(API_URL + 'users', param)
            .then(resp => {
                if (resp.status == 200) {
                    cb(resp.data)
                }
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    loginAsGuest(data, cb, errorCB) {
        axios
            .post(API_URL + 'loginAs', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    getAllQuestions(param, cb, errorCB) {
        axios
            .post(API_URL + 'getQuestions', param)
            .then(resp => {
                if (resp.status == 200) {
                    cb(resp.data)
                }
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    getAllCategories(params, cb, errorCB) {
        axios
            .post(API_URL + 'categories', params)
            .then(resp => {
                if (resp.status == 200) {
                    cb(resp.data)
                }
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    getAllClientSpotters(params, cb, errorCB) {
        axios
            .post(API_URL + 'clientSpotters', params)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    getAllNotificationSpotters(params, cb, errorCB) {
        axios
            .post(API_URL + 'getNotificationSpotters', params)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    getEmailTemplate(cb, errorCB) {
        axios
            .get(API_URL + 'get/Email/Template')
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    sendSpotterNotifications(params, cb, errorCB) {
        axios
            .post(API_URL + 'sendSpotterNotifications', params)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    spotterSpotCount(params, cb, errorCB) {
        axios
            .post(API_URL + 'spotterSpotCount', params)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    spotterBirthdays(params, cb, errorCB) {
        axios
            .post(API_URL + 'spotterBirthdays', params)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    getAllSpots(params, cb, errorCB) {
        axios
            .post(API_URL + 'getAllSpots', params)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    updateSpotStatus(params, cb, errorCB) {
        axios
            .post(API_URL + 'updateSpotStatus', params)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    getAllLocations(params, cb, errorCB) {
        axios
            .post(API_URL + 'locations', params)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    getAllSpotType(params, cb, errorCB) {
        axios
            .post(API_URL + 'spotTypes', params)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    getAllPermissions(cb, errorCB) {
        axios
            .get(API_URL + 'getAllPermissions')
            .then(resp => {
                if (resp.status == 201) {
                    cb(resp.data)
                }
            })
            .catch(err => {
                errorCB(err.response.data)
            })
    },
    add_edit_roles(data, cb, errorCB) {
        axios
            .post(API_URL + 'add_edit_roles', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    get_edit_roles(id, cb, errorCB) {
        axios
            .get(API_URL + 'add_edit_roles/' + id)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response.data)
            })
    },
    get_edit_user(id, cb, errorCB) {
        axios
            .get(API_URL + 'users/edit/' + id)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    editQuestion(id, cb, errorCB) {
        axios
            .get(API_URL + 'questions/edit/' + id)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    getEditCategory(id, cb, errorCB) {
        axios
            .get(API_URL + 'categorise/edit/' + id)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    getEditLocation(id, cb, errorCB) {
        axios
            .get(API_URL + 'locations/edit/' + id)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    getEditSpotType(id, cb, errorCB) {
        axios
            .get(API_URL + 'spotTypes/edit/' + id)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    getSpotter(id, cb, errorCB) {
        axios.get(API_URL + 'getSpotters/edit/' + id)
            .then(resp => {

                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    getSpot(id, cb, errorCB) {
        let data = {
            id: id
        }
        axios.get(API_URL + 'getSpot/data/' + id, data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    getDashboardData(data, cb, errorCB) {
        axios.post(API_URL + 'getDashboardData', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    getSpotPerMonth(data, cb, errorCB) {
        axios.post(API_URL + 'getSpotPerMonth', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    getSpotPerLocation(data, cb, errorCB) {
        axios.post(API_URL + 'getSpotPerLocation', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    getSpotPerType(data, cb, errorCB) {
        axios.post(API_URL + 'getSpotPerType', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    getClientUsers(cb, errorCB) {
        axios.get(API_URL + 'getClient/users/data')
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    getUserSpotTypes(cb, errorCB) {
        axios.get(API_URL + 'getClient/spotTypes/data')
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    getClientLocations(cb, errorCB) {
        axios.get(API_URL + 'getClient/locations/data')
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    getDataCollectionQuestions(cb, errorCB) {
        axios.get(API_URL + 'getClient/collectionQuestions/data')
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err)
            })
    },
    add_edit_users(data, cb, errorCB) {
        axios
            .post(API_URL + 'add_edit_users', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    addUpdateSpotters(data, cb, errorCB) {
        axios
            .post(API_URL + 'addUpdateSpotters', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    updateSpot(data, cb, errorCB) {
        axios.post(API_URL + 'updateSpot', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    addEditQuestion(data, cb, errorCB) {
        axios
            .post(API_URL + 'addUpdateAppQuestion', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err)
            })
    },
    addEditCategories(data, cb, errorCB) {
        axios
            .post(API_URL + 'addEditCategories', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    addEditLocations(data, cb, errorCB) {
        axios
            .post(API_URL + 'addEditLocations', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    addEditSpotType(data, cb, errorCB) {
        axios
            .post(API_URL + 'addEditSpotTypes', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    updateUserProfile(data, cb, errorCB) {
        axios
            .post(API_URL + 'updateUserProfile', data, { headers: { "Content-Type": "multipart/form-data" } })
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    updateAppSettings(data, cb, errorCB) {
        axios
            .post(API_URL + 'updateAppSettings', data, { headers: { "Content-Type": "multipart/form-data" } })
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    updateEditorContent(data, cb, errorCB) {
        axios
            .post(API_URL + 'updateEditorContent', data, { headers: { "Content-Type": "multipart/form-data" } })
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    updateEmailTemplateEditorContent(data, cb, errorCB) {
        axios
            .post(API_URL + 'updateEmailTemplateEditorContent', data, { headers: { "Content-Type": "multipart/form-data" } })
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    getClientAdminSpots(data, cb, errorCB) {
        axios
            .post(API_URL + 'getClientAdminSpots', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.data)
            })
    },
    getAppSettings(cb, errorCB) {
        axios
            .get(API_URL + 'getAppSettings')
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    saveEditorImage(data, cb, errorCB) {
        axios
            .post(API_URL + 'saveEditorImage', data, { headers: { "Content-Type": "multipart/form-data" } })
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    getAuthUser(data, cb, errorCB) {
        axios
            .get(API_URL + 'getUserProfile', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    logout(cb, errorCB) {
        axios
            .post(API_URL + 'logout')
            .then(resp => {
                if (resp.status == 200) {
                    cb(resp.data)
                }
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    getcleints(params, cb, errorCB) {
        axios.post(API_URL + 'getcleints', params)
            .then(resp => {
                if (resp.status == 200) {
                    cb(resp.data)
                }
            })
            .catch(err => {
                errorCB(err.response)
            })
    },
    get_client(data, cb, errorCB) {
        axios
            .post(API_URL + 'get_client', data)
            .then(resp => {
                if (resp.status == 200) {
                    cb(resp.data)
                }
            })
            .catch(err => {
                errorCB(err.response.data)
            })
    },
    get_all_cleints(data, cb, errorCB) {
        axios
            .get(API_URL + 'getAllClients', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response)
            })
    },

    addBankinfo(data, cb, errorCB) {
        axios
            .post(API_URL + 'createAccount', data)
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response.data)
            })
    },

    getConnectAccount(cb, errorCB) {
        axios
            .get(API_URL + 'get_connect_account')
            .then(resp => {
                cb(resp.data)
            })
            .catch(err => {
                errorCB(err.response.data)
            })
    }
}

export default API
